import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"
const FBA_Inbounds = () => {
  return (
    <Layout>
      <Seo
        title="Amazon FBA Inbounds"
        description="Track all your Amazon FBA Inbound shipments and get notified if something went wrong"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">Amazon FBA Inbounds Tracker</Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszero-FBA-Inbounds-01.png"
                    alt="Amazon FBA inbounds"
                    placeholder="tracedSVG"
                    layout="constrained"
                    //aspectRatio={1 / 1}
                    height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8} className="pb-5">
                  <ul>
                    <li>
                      All your progressing FBA shipments will be displayed
                      including all the items sent and their boxes number and
                      quantities.
                    </li>
                    <li>
                      azszero will find out which shipping company is being used
                      and provide direct tracking to that shipment.
                    </li>
                    <li>
                      You get notified if the shipment is delayed, not received
                      in time, or took a long time receiving.
                    </li>
                    <li>
                      incase of a problem, the system will instruct you of
                      suggestions and actions you might consider taking.
                    </li>
                    <li>
                      The FBA inbounds will track all marketplaces shipments.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default FBA_Inbounds
